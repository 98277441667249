import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { Lightbox } from 'ngx-lightbox';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { catchError, EMPTY, finalize } from 'rxjs';
import { AgencyScore } from 'src/app/models/agency-score';
import { Car } from 'src/app/models/car';
import { Review, ReviewDto } from 'src/app/models/review';
import { Specs } from 'src/app/models/specs';
import { routes } from 'src/app/shared/routes/routes';
import { CarService } from 'src/app/shared/services/car.service';
import { DataService } from 'src/app/shared/services/data/data.service';
import {
  interestedCars,
  listingDetails,
  thumbnails,
} from 'src/app/shared/services/model/model';
interface data {
  value: string;
}
interface gallery {
  src: string;
}
@Component({
  selector: 'app-listing-details',
  templateUrl: './car-details.component.html',
  styleUrls: ['./car-details.component.scss'],
})
export class ListingDetailsComponent implements OnInit {
  public routes = routes;
  public gallery: Array<gallery> = [];
  public listingDetails: listingDetails[] = [];
  public thumbnails: thumbnails[] = [];
  public interestedCars: interestedCars[] = [];
  showTimePicker: Array<string> = [];
  myTime: Date = new Date();
  myTime2: Date = new Date();
  public selectedValue1!: string;
  public selectedValue2!: string;

  agencyScore:AgencyScore | null = {score: 0,totalReviewers:0}; 

  car!: Car;
  reviews: ReviewDto[]|null = [];
  carId: string | null = null;
  chunkedFeatures: string[][] = [];
  specifications: Specs[] = [];


  selectedList1: data[] = [
    { value: 'Newyork Office - 78, 10th street Laplace USA' },
    { value: 'Newyork Office - 12, 5th street USA' },
  ];
  selectedList2: data[] = [
    { value: 'Newyork Office - 78, 10th street Laplace USA' },
    { value: 'Newyork Office - 12, 5th street USA' },
  ];



  public readonly bsStartDateConfig: Partial<BsDaterangepickerConfig> = {
    minDate: new Date(),
    adaptivePosition: true,
    useUtc: true,
    isAnimated: true,
    startView: 'day',
    returnFocusToInput: false,
    maxDateRange: 90,//max is 90 days
  }


  get endDatePlaceholder() {
    return this.datePipe.transform(new Date(new Date().setDate(new Date().getDate() + 15)), 'dd/MM/yyyy') || '';
  }
  get startDatePlaceholder() {
    return this.datePipe.transform(new Date(), 'dd/MM/yyyy') || '';
  }



  public readonly bsEndDateConfig: Partial<BsDaterangepickerConfig> = {
    minDate: new Date(new Date().setDate(new Date().getDate() + 1)),
    adaptivePosition: true,
    useUtc: true,
    isAnimated: true,
    startView: 'day',
    returnFocusToInput: false,
    maxDateRange: 90,//max is 90 days
  }

  constructor(
    private data: DataService,
    private datePipe: DatePipe,
    private _lightbox: Lightbox,
    private readonly carService: CarService,
    private route: ActivatedRoute,
    private router: Router,
    private readonly spinner: NgxSpinnerService,
    private toastr: ToastrService,

  ) {
    if (!!this.route.snapshot.paramMap.get('id')) {
      this.carId = this.route.snapshot.paramMap.get('id');
    } else {
      console.log('not found');
      this.toastr.error("Car not found")
      this.router.navigate(['/home']);
    }

    this.listingDetails = this.data.listingDetails;
    this.thumbnails = this.data.thumbnails;
    this.interestedCars = this.data.interestedCars;
    for (let i = 1; i <= 12; i++) {
      const src = 'assets/img/gallery/gallery-thumb-0' + i + '.jpg';
      this.gallery.push({ src: src });
    }
  }

  ngOnInit(): void {
    this.spinner.show();
    this.carService.searchCarById(this.carId!)
      .pipe(
        finalize(() => this.spinner.hide()),
        catchError((error) => {
          this.handleError(error,"car details");
          return EMPTY;
        })
      )
      .subscribe({
        next: (apiResponse) => {
          this.car = apiResponse.data!;
          this.chunkedFeatures = this.chunkArray(this.car.features, 4);
          this.specifications = this.initSpecs();
        }
      });

    this.spinner.show();
    this.carService.getReviewsByAgencyId("RYr7AtIYcBZ63ozcU369")
    .pipe(
      finalize(() => this.spinner.hide()),
      catchError((error) => {
        this.handleError(error,"reviews");
        return EMPTY;
      })
    )
    .subscribe({
      next: (apiResponse) => {
        this.reviews = apiResponse.data;
      }
    });

    this.spinner.show(); 
    this.carService.calculateAgencyScore("RYr7AtIYcBZ63ozcU369")
    .pipe(
      finalize(() => this.spinner.hide()),
      catchError((error) => {
        this.handleError(error,"agency score");
        return EMPTY;
      })
    )
    .subscribe({
      next: (apiResponse) => {
        this.agencyScore = apiResponse.data;        
      }
    });

  }

  convertToReadableDate(dateObject:any):string{
    // Convert to milliseconds and create a Date object
    const date = new Date(dateObject.seconds * 1000);

    // Convert to a human-readable date string
    return date.toLocaleString();
  }

  public onStartDateSelected(value: any): void {
    console.log(`startDate : ${value}`);
  }


  private handleError(error: any,dataToLoad:string): void {
    this.toastr.error('Unable to load car details. Please try again later.');
    this.router.navigate(['/not-found'])
  }



  private initSpecs(): Specs[] {
    return [
      {
        spec: 'bodywork',
        value: this.car.bodywork ?? undefined,
        icon: 'assets/img/specification/specification-icon-1.svg'
      },
      {
        spec: 'brand',
        value: this.car.brand ?? undefined,
        icon: 'assets/img/specification/specification-icon-2.svg'
      },
      {
        spec: 'transmission',
        value: this.car.brand ?? undefined,
        icon: 'assets/img/specification/specification-icon-3.svg'
      },
      {
        spec: 'fuel-type',
        value: this.car.fuelType ?? undefined,
        icon: 'assets/img/specification/specification-icon-4.svg'
      },

      {
        spec: 'year',
        value: this.car.year ?? undefined,
        icon: 'assets/img/specification/specification-icon-7.svg'
      },
      {
        spec: 'climatisation',
        value: this.car?.features?.find(s => s.includes('limate')) ?? undefined,
        icon: 'assets/img/specification/specification-icon-8.svg',
        isFeature: true
      },
      {
        spec: 'vin',
        value: this.car.plateNumber ?? undefined,
        icon: 'assets/img/specification/specification-icon-9.svg'
      },
      {
        spec: 'number-of-seats',
        value: this.car.numberOfSeats ?? undefined,
        icon: 'assets/img/specification/specification-icon-14.svg'
      },
      {
        spec: 'break-type',
        value: this.car.brakeType ?? undefined,
        icon: 'assets/img/specification/specification-icon-11.svg'
      },
      {
        spec: 'motor-type',
        value: this.car.motorType ?? undefined,
        icon: 'assets/img/specification/specification-icon-12.svg'
      },
      {
        spec: 'number-of-doors',
        value: (!!this.car.numberOfDoors && this.car.numberOfDoors !== 0) ? this.car.numberOfDoors : undefined,
        icon: 'assets/img/specification/specification-icon-10.svg'
      },
    ];
  }

  get specs() {
    return this.specifications.filter(spec => !!spec && spec.value !== undefined);
  }

  // Helper function to chunk the array into groups of size 'size'
  private chunkArray(array: string[] | null, size: number): string[][] {
    const result = [];
    if (array!! && array.length !== 0) {
      for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
      }
      return result;
    }
    return [];
  }

  public slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: '.slider-nav-thumbnails',
    nav: true,
  };
  public slideConfig2 = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.detail-bigimg',
    dots: false,
    arrows: false,
    centerMode: false,
    focusOnSelect: true,
    nav: true,
  };
  interestedCarsOptions: OwlOptions = {
    loop: true,
    margin: 24,
    nav: false,
    dots: false,
    autoplay: false,
    smartSpeed: 2000,
    navText: [
      "<i class='fa-solid fa-arrow-left'></i>",
      "<i class='fa-solid fa-arrow-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },

      550: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  toggleTimePicker(value: string): void {
    if (this.showTimePicker[0] !== value) {
      this.showTimePicker[0] = value;
    } else {
      this.showTimePicker = [];
    }
  }
  formatTime(date: Date) {
    const selectedDate: Date = new Date(date);
    return this.datePipe.transform(selectedDate, 'h:mm a');
  }

  open(index: number, albumArray: Array<any>): void {
    this._lightbox.open(albumArray, index);
  }

  close(): void {
    this._lightbox.close();
  }
}
