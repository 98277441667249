import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AgencyScore } from 'src/app/models/agency-score';
import { ApiResponse } from 'src/app/models/api-response';
import { Car } from 'src/app/models/car';
import { CarFilter } from 'src/app/models/carFilter';
import { Review, ReviewDto } from 'src/app/models/review';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CarService {
  private carApiUrl = environment.apiUrl + '/public/cars';
  private reviewApiUrl = environment.apiUrl + '/public/review';

  constructor(private http: HttpClient) {}

  // Fetch filtered paginated cars
  findFilteredAllCars(
    page: number,
    size: number,
    sortBy: string,
    filters: CarFilter
  ): Observable<Car[]> {
    const params = new HttpParams()
      .set('page', page)
      .set('size', size)
      .set('sortBy', sortBy);

    return this.http.post<Car[]>(this.carApiUrl + '/filter', filters, { params });
  }

  findSearchedAllCars(
    page: number,
    size: number,
    sortBy: string,
    filters: CarFilter
  ): Observable<Car[]> {
    const params = new HttpParams()
      .set('page', page)
      .set('size', size)
      .set('sortBy', sortBy);

    return this.http.post<Car[]>(this.carApiUrl + '/search', filters, { params });
  }

  getTotalFilteredCarCount(filters: CarFilter): Observable<number> {
    return this.http.post<number>(`${this.carApiUrl}/count-filtered`, filters);
  }

  getTotalSearchedCarCount(filters: CarFilter): Observable<number> {
    return this.http.post<number>(`${this.carApiUrl}/count-searched`, filters);
  }

  searchCarById(id:string):Observable<ApiResponse<Car>>{
    return this.http.get<ApiResponse<Car>>(`${this.carApiUrl}/search/${id}`);
  }

  getReviewsByAgencyId(id:string):Observable<ApiResponse<ReviewDto[]>>{
    return this.http.get<ApiResponse<ReviewDto[]>>(`${this.reviewApiUrl}/agency/${id}`);
  }

  calculateAgencyScore(id:string):Observable<ApiResponse<AgencyScore>>{
    return this.http.get<ApiResponse<AgencyScore>>(`${this.reviewApiUrl}/agency/${id}/score`);
  }
}
